import React, { useState, useEffect, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import Chart from 'chart.js/auto';
import { Radar } from 'react-chartjs-2';
import perfilImage1 from '../images/profile/1A.png';
import perfilImage2 from '../images/profile/1B.png';
import perfilImage3 from '../images/profile/2A.png';
import perfilImage4 from '../images/profile/2B.png';
import perfilImage5 from '../images/profile/3A.png';
import perfilImage6 from '../images/profile/3B.png';
import perfilImage7 from '../images/profile/4A.png';
import perfilImage8 from '../images/profile/4B.png';
import perfilImage9 from '../images/profile/5A.png';
import perfilImage10 from '../images/profile/5B.png';
import perfilImage11 from '../images/profile/6A.png';
import perfilImage12 from '../images/profile/6B.png';
import perfilImage13 from '../images/profile/7A.png';
import perfilImage14 from '../images/profile/7B.png';
import close from '../images/close-stats.png';
import TwoButtons from '../components/twoButtons';
import visualImg from '../images/visual.png';
import olfativaImg from '../images/olfativa.png';
import gustoImg from '../images/gusto.png';
import variedadImg from '../images/variedad.png';
import paisImg from '../images/pais.png';
import regionImg from '../images/region.png';
import precioImg from '../images/precio.png';
import wineA from '../images/wine-a.png';
import wineB from '../images/wine-b.png';
import wineC from '../images/wine-c.png';
import wineD from '../images/wine-d.png';

const ExampleRadarChart = () => {
    const [profileInfo, setProfileInfo] = useState({
        username: '',
        average: 0.00,
        wines: 0,
        profile: 0,
        id: 0
    });

    const [userData, setUserData] = useState({ profile: 0 });
    const [myUserData, setMyUserData] = useState({ profile: 0 });
    const [selectedButton, setSelectedButton] = useState('Mensual');
    const [personalData, setPersonalData] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [generalData, setGeneralData] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [personalLabel, setPersonalLabel] = useState('Personal');
    const [generalLabel, setGeneralLabel] = useState('Nivel Participantes');

    const { id } = useParams();
    const images = [
        perfilImage1, perfilImage2, perfilImage3, perfilImage4,
        perfilImage5, perfilImage6, perfilImage7, perfilImage8,
        perfilImage9, perfilImage10, perfilImage11, perfilImage12,
        perfilImage13, perfilImage14
    ];
    const [myWinesOrder, setMyWinesOrder] = useState({});
    const [generalWinesOrder, setGeneralWinesOrder] = useState([]);

    const getWineImage = (wine) => {
        switch (wine) {
            case 'A':
                return wineA;
            case 'B':
                return wineB;
            case 'C':
                return wineC;
            case 'D':
                return wineD;
            default:
                return null;
        }
    };

    useEffect(() => {
        fetchUserData(id);
        const storedUserInfo = localStorage.getItem('userLogged');
        if (storedUserInfo) {
            const userInfo = JSON.parse(storedUserInfo);
            setProfileInfo({
                username: userInfo.username,
                average: userInfo.average || 0.00,
                wines: userInfo.wines || 0,
                id: userInfo.id || 0,
                profile: userInfo.profile || 'default'
            });
            setPersonalLabel(userInfo.username);
            fetchMyUserData(userInfo.id);
        }
    }, []);

    useEffect(() => {
        if (profileInfo.id !== 0 && userData.id !==0) {
            fetchPersonalData(profileInfo.id);
            if (profileInfo.id == id) {
                console.log(`profileInfoId: ${profileInfo.id}`);
                console.log(`Id: ${id}`);
                console.log("Es mi estadistica");
                fetchGeneralData('');
            } else {
                console.log(`profileInfoId: ${profileInfo.id}`);
                console.log(`Id: ${id}`);
                console.log("No es mi estadistica");
                console.log(userData);
                fetchSecondUserData(userData.id);
                setGeneralLabel(userData.username);
            }
            fetchWinesOrderData(profileInfo.id);
        }
    }, [userData.id, profileInfo.id]);

    // Función para manejar los datos de mi usuario y sus winegangs (primer selector)
    const fetchUserData = (userId) => {
        console.log(`Fetching user data for userId: ${userId}`);
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/user/${userId}`;
        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                setUserData(JSON.parse(this.responseText));
            }
        };
        xhr.send();
    };

    const fetchMyUserData = () => {
        const storedUserInfo = JSON.parse(localStorage.getItem('userLogged'));
        const myId = storedUserInfo.id;
        console.log(`Fetching my user data for myId: ${myId}`);

        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/user/${myId}`;
        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                setMyUserData(JSON.parse(this.responseText));
            }
        };
        xhr.send();
    };

    const fetchPersonalData = (userId) => {
        console.log(`Fetching personal data for userId: ${userId}, period: ${selectedButton}`);

        const period = selectedButton === 'Mensual' ? 'last_month' : 'all_time';
        const url = `${process.env.REACT_APP_API_URL}/userStats/${userId}?period=${period}`;

        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                const userStats = JSON.parse(this.responseText);
                setPersonalData([
                    userStats.visual_stats * 100,
                    userStats.olfactory_stats * 100,
                    userStats.taste_stats * 100,
                    userStats.variety_stats * 100,
                    userStats.country_stats * 100,
                    userStats.region_stats * 100,
                    userStats.price_stats * 100
                ]);
                console.log("Updated personal data:", [
                    userStats.visual_stats * 100,
                    userStats.olfactory_stats * 100,
                    userStats.taste_stats * 100,
                    userStats.variety_stats * 100,
                    userStats.country_stats * 100,
                    userStats.region_stats * 100,
                    userStats.price_stats * 100
                ], "Server response:", userStats);
            }
        };
        xhr.send();
    };
    const fetchSecondUserData = (userId) => {
        console.log(`Fetching second user data for userId: ${userId}, period: ${selectedButton}`);

        const period = selectedButton === 'Mensual' ? 'last_month' : 'all_time';
        const url = `${process.env.REACT_APP_API_URL}/userStats/${userId}?period=${period}`;

        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                const userStats = JSON.parse(this.responseText);
                setGeneralData([
                    userStats.visual_stats * 100,
                    userStats.olfactory_stats * 100,
                    userStats.taste_stats * 100,
                    userStats.variety_stats * 100,
                    userStats.country_stats * 100,
                    userStats.region_stats * 100,
                    userStats.price_stats * 100
                ]);
                console.log("Updated second user data:", [
                    userStats.visual_stats * 100,
                    userStats.olfactory_stats * 100,
                    userStats.taste_stats * 100,
                    userStats.variety_stats * 100,
                    userStats.country_stats * 100,
                    userStats.region_stats * 100,
                    userStats.price_stats * 100
                ], "Server response:", userStats);
            }
        };
        xhr.send();
    };
    const fetchFirstWinegangData = (winegangId) => {
        console.log(`Fetching first winegang data for winegangId: ${winegangId}, period: ${selectedButton}`);

        const period = selectedButton === 'Mensual' ? 'last_month' : 'all_time';
        const url = `${process.env.REACT_APP_API_URL}/winegangStats/${winegangId}?period=${period}`;

        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                const userStats = JSON.parse(this.responseText);
                setPersonalData([
                    userStats.visual_stats * 100,
                    userStats.olfactory_stats * 100,
                    userStats.taste_stats * 100,
                    userStats.variety_stats * 100,
                    userStats.country_stats * 100,
                    userStats.region_stats * 100,
                    userStats.price_stats * 100
                ]);
                console.log("Updated winegang data:", [
                    userStats.visual_stats * 100,
                    userStats.olfactory_stats * 100,
                    userStats.taste_stats * 100,
                    userStats.variety_stats * 100,
                    userStats.country_stats * 100,
                    userStats.region_stats * 100,
                    userStats.price_stats * 100
                ], "Server response:", userStats);
            }
        };
        xhr.send();
    };
    const fetchSecondWinegangData = (winegangId) => {
        console.log(`Fetching second winegang data for winegangId: ${winegangId}, period: ${selectedButton}`);

        const period = selectedButton === 'Mensual' ? 'last_month' : 'all_time';
        const url = `${process.env.REACT_APP_API_URL}/winegangStats/${winegangId}?period=${period}`;

        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                const userStats = JSON.parse(this.responseText);
                setGeneralData([
                    userStats.visual_stats * 100,
                    userStats.olfactory_stats * 100,
                    userStats.taste_stats * 100,
                    userStats.variety_stats * 100,
                    userStats.country_stats * 100,
                    userStats.region_stats * 100,
                    userStats.price_stats * 100
                ]);
                console.log("Updated winegang data:", [
                    userStats.visual_stats * 100,
                    userStats.olfactory_stats * 100,
                    userStats.taste_stats * 100,
                    userStats.variety_stats * 100,
                    userStats.country_stats * 100,
                    userStats.region_stats * 100,
                    userStats.price_stats * 100
                ], "Server response:", userStats);
            }
        };
        xhr.send();
    };

    const fetchWinesOrderData = (userId) => {
        console.log(`Fetching wines order data for userId: ${userId}`);
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/wines-order/${userId}`;
        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                const winesOrderData = JSON.parse(this.responseText);
                setMyWinesOrder(winesOrderData.last_order);
                setGeneralWinesOrder(winesOrderData.preferences);
            }
        };
        xhr.send();
    };

    // Función para manejar los datos generales o del otro usuario y sus winegangs (segundo selector)
    const fetchGeneralData = () => {
        console.log(`Fetching general data, period: ${selectedButton}`);

        const period = selectedButton === 'Mensual' ? 'last_month' : 'all_time';
        const url = `${process.env.REACT_APP_API_URL}/generalStats?period=${period}`;

        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                const userStats = JSON.parse(this.responseText);
                setGeneralData([
                    userStats.visual_stats * 100,
                    userStats.olfactory_stats * 100,
                    userStats.taste_stats * 100,
                    userStats.variety_stats * 100,
                    userStats.country_stats * 100,
                    userStats.region_stats * 100,
                    userStats.price_stats * 100
                ]);
                console.log("Updated winegang data:", [
                    userStats.visual_stats * 100,
                    userStats.olfactory_stats * 100,
                    userStats.taste_stats * 100,
                    userStats.variety_stats * 100,
                    userStats.country_stats * 100,
                    userStats.region_stats * 100,
                    userStats.price_stats * 100
                ], "Server response:", userStats);
            }
        };
        xhr.send();
    };

    const options = {
        scales: {
            r: {
                angleLines: { color: 'rgba(255, 255, 255, 1)' },
                grid: { color: 'rgba(255, 255, 255, 0.5)' },
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: { display: false },
                pointLabels: { display: false, font: { size: 0 }, color: 'white' },
            },
        },
        plugins: {
            legend: false,
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = context.dataset.label || '';
                        const value = context.raw !== undefined ? context.raw.toFixed(2) : '0.00';
                        return `${label}: ${value}%`; // Aquí agregamos el %
                    },
                },
            },
        },
        animation: { duration: 100 },
    };

    const handleGoBack = () => window.history.back();

    const handleFirstSelectorChange = (event) => {
        console.log(event.target.value);
        if (event.target.value === 'user') {
            fetchPersonalData(profileInfo.id);
        } else {
            fetchFirstWinegangData(event.target.value);
        }
        
        setPersonalLabel(event.target.options[event.target.selectedIndex].text); // Actualiza el label del dataset personal
    };

    const handleSecondSelectorChange = (event) => {
        if (event.target.value === 'general') {
            fetchGeneralData();
        } else if(event.target.value === 'user') {
            fetchSecondUserData(userData.id);
        } else {
            fetchSecondWinegangData(event.target.value);
        }
        setGeneralLabel(event.target.options[event.target.selectedIndex].text); // Actualiza el label del dataset general
    };

    const data = {
        labels: ['Visual', 'Olfativa', 'Gusto', 'Variedad', 'Pais', 'Region', 'Precio'],
        datasets: [
            {
                label: personalLabel,
                data: personalData,
                backgroundColor: 'rgba(251, 113, 42, 0.2)',
                borderColor: 'rgba(251, 113, 42, 1)',
                borderWidth: 3,
            },
            {
                label: generalLabel,
                data: generalData,
                backgroundColor: 'rgba(3, 230, 105, 0.2)',
                borderColor: 'rgba(3, 230, 105, 1)',
                borderWidth: 3,
                tension: 0,
            },
        ],
    };
    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
        fetchPersonalData(profileInfo.id);
        fetchGeneralData('general');
    };

    return (
        <div className="body-grey">
            <div className="user-container">
                <div className="profile-image">
                    <img src={images[userData.profile]} alt={images[userData.profile]} />
                </div>
                <div className="profile-info">
                    <h4>WINE POLICE DEPT.</h4>
                    <div className="row">
                        <div className="col-6">{userData.username}<br />Ranking del mes: XX<br />Media : {userData.average}<br />Vinos catados : {userData.wines}</div>
                        <div className="col-2">WINEGANG:</div>
                        <div className="col-4"><br /></div>
                    </div>
                </div>
            </div>
            <div className="blur">
                <div className="stats-content row">
                    <button className="stats-close" onClick={handleGoBack}><img src={close} alt="close" /></button>
                    <div className="col-md-8 stats-first">
                        <div className="first-buttons">
                            <button className={selectedButton === 'Mensual' ? 'selected-button' : ''} onClick={() => handleButtonClick('Mensual')}>Mensual</button>
                            <button className={selectedButton === 'General' ? 'selected-button' : ''} onClick={() => handleButtonClick('General')}>Nivel</button>
                        </div>
                        <div className="chart-container">
                            <Radar data={data} options={options} />
                            <div className="chart-img visual">
                                <img src={visualImg} alt="Visual" className="chart-img" />
                                <div className="toltip">Fase visual</div>
                            </div>
                            <div className="chart-img olfativa">
                                <img src={olfativaImg} alt="Olfativa" className="chart-img" />
                                <div className="toltip">Fase olfativa</div>
                            </div>
                            <div className="chart-img gusto">
                                <img src={gustoImg} alt="Gusto" className="chart-img" />
                                <div className="toltip">Fase gustativa</div>
                            </div>
                            <div className="chart-img variedad">
                                <img src={variedadImg} alt="Variedad" className="chart-img" />
                                <div className="toltip">Variedad</div>
                            </div>
                            <div className="chart-img pais">
                                <img src={paisImg} alt="Pais" className="chart-img" />
                                <div className="toltip">País</div>
                            </div>
                            <div className="chart-img region">
                                <img src={regionImg} alt="Región" className="chart-img" />
                                <div className="toltip">Región</div>
                            </div>
                            <div className="chart-img precio">
                                <img src={precioImg} alt="Precio" className="chart-img" />
                                <div className="toltip">Precio</div>
                            </div>
                        </div>
                        {Number(profileInfo.id) === Number(userData.id) ? (
                            <div className="dropdowns-container">
                                <select className="stats-dropdown first" onChange={handleFirstSelectorChange}>
                                    <option value="user">{userData.username}</option>
                                    {userData.winegangs && userData.winegangs.length > 0 && (
                                        userData.winegangs.map((winegang, index) => (
                                            <Fragment key={index}>
                                                <option value={winegang.id}>{winegang.winegang_name}</option>
                                            </Fragment>
                                        ))
                                    )}
                                </select>
                                <select className="stats-dropdown second" onChange={handleSecondSelectorChange}>
                                    <option value="general">Nivel Participantes</option>
                                    {userData.winegangs && userData.winegangs.length > 0 && (
                                        userData.winegangs.map((winegang, index) => (
                                            <Fragment key={index}>
                                                <option value={winegang.id}>{winegang.winegang_name}</option>
                                            </Fragment>
                                        ))
                                    )}
                                </select>
                            </div>
                        ) : (
                            <div className="dropdowns-container">
                                <select className="stats-dropdown first" onChange={handleFirstSelectorChange}>
                                    <option value="user">{myUserData.username}</option>
                                    {myUserData.winegangs && myUserData.winegangs.length > 0 && (
                                        myUserData.winegangs.map((winegang, index) => (
                                            <Fragment key={index}>
                                                <option value={winegang.id}>{winegang.winegang_name}</option>
                                            </Fragment>
                                        ))
                                    )}
                                </select>
                                <select className="stats-dropdown second" onChange={handleSecondSelectorChange}>
                                    <option value="user">{userData.username}</option>
                                    {userData.winegangs && userData.winegangs.length > 0 && (
                                        userData.winegangs.map((winegang, index) => (
                                            <Fragment key={index}>
                                                <option value={winegang.id}>{winegang.winegang_name}</option>
                                            </Fragment>
                                        ))
                                    )}
                                </select>
                            </div>
                        )}

                    </div>
                    <div className="col-md-4 stats-second">
                        <div className="title">VINO DEL MES</div>
                        <div className="wines">
                            {myWinesOrder && ['first', 'second', 'third', 'fourth'].map((position, index) => (
                                <div className="wine" key={index}>
                                    <img src={getWineImage(myWinesOrder[position])} alt="Wine" className="wine-img" />
                                    <span>{myWinesOrder[position]}</span>
                                </div>
                            ))}
                            <div className="wines-title">Personal</div>
                        </div>
                        <div className="wines">
                            {generalWinesOrder.map((order, index) => (
                                <div className="wine" key={index}>
                                    <img src={getWineImage(order.name)} alt="Wine" className="wine-img" />
                                    <span>{order.name}</span>
                                    <span>{order.percent}</span>
                                </div>
                            ))}
                            <div className="wines-title">General</div>
                        </div>
                    </div>
                </div>
            </div>
            <TwoButtons />
        </div>
    );
};

export default ExampleRadarChart;
