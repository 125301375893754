import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Pagina1 from '../testComponents/visualComponent';
import Pagina2 from '../testComponents/olfactoryComponent';
import Pagina3 from '../testComponents/tasteComponent';
import Pagina4 from '../testComponents/noteComponent';
import Pagina5 from '../testComponents/orderComponent';
import TwoButtons from '../components/twoButtons';
import close from '../images/close-stats.png';

// Importa las imágenes locales
import ayuda1 from '../images/ayuda/visual-vertical.jpg';
import ayuda2 from '../images/ayuda/olfativa-vertical.jpg';
import ayuda3 from '../images/ayuda/gustativa-vertical.jpg';
import vinoA from '../images/ayuda/A.jpg';
import vinoB from '../images/ayuda/B.jpg';
import vinoC from '../images/ayuda/C.jpg';
import vinoD from '../images/ayuda/D.jpg';
const driveBaseUrl = 'https://drive.google.com/uc?export=view&id=';
const helpUrls = [
    ayuda1,
    ayuda2,
    ayuda3,
    {
        A: 'https://eventosadmin.winegang.es/src/A.jpg', // Cambia con el ID correcto de la imagen A.jpg
        B: 'https://eventosadmin.winegang.es/src/B.jpg', // Cambia con el ID correcto de la imagen B.jpg
        C: 'https://eventosadmin.winegang.es/src/C.jpg', // Cambia con el ID correcto de la imagen C.jpg
        D: 'https://eventosadmin.winegang.es/src/D.jpg'  // Cambia con el ID correcto de la imagen D.jpg
    },
    ''
];
const SliderComponent = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [test, setTest] = useState(null);
    const [currentTest, setCurrentTest] = useState(null);
    const [isHelpPopupVisible, setHelpPopupVisible] = useState(false);
    const [isSubmitPopupVisible, setSubmitPopupVisible] = useState(false);
    const [popupImage, setPopupImage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const currentTest = JSON.parse(localStorage.getItem('currentTest'));
        if (currentTest) {
            fetchTestDetails(currentTest.id);
        }
    }, []);

    const fetchTestDetails = (testId) => {
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/getTest/${testId}`;
        xhr.open("GET", url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                const data = JSON.parse(xhr.responseText);
                setTest(data.object);
                setCurrentTest(data.title);
                console.log("Test Details Loaded: ", data);
            }
        };
        xhr.send();
    };

    const handleNext = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrev = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleShowInstructions = () => {
        const instructionsDiv = document.getElementById('instructions-div');
        if (instructionsDiv) {
            instructionsDiv.style.display = 'block';
        }
    };

    const handleHelpClick = () => {
        let imageUrl;
        if (currentPage === 4 && currentTest) {
            const currentTestTitle = currentTest;
            const pageFourUrls = helpUrls[3];
            imageUrl = pageFourUrls[currentTestTitle] || '';
            console.log(`Current Test Title: ${currentTestTitle}, URL: ${imageUrl}`);
        } else {
            imageUrl = helpUrls[currentPage - 1];
        }

        if (imageUrl) {
            setPopupImage(imageUrl);
            setHelpPopupVisible(true);
        } else {
            console.log("No hay imagen de ayuda definida para esta página.");
        }
    };

    useEffect(() => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (isIOS) {
            document.body.classList.add("safari");
        }
        return () => {
            if (isIOS) {
                document.body.classList.remove("safari");
            }
        };
    }, []);

    const saveAnswersRef = useRef(null);

    const handleSaveAnswers = () => {
        if (saveAnswersRef.current) {
            saveAnswersRef.current();
        }
    };

    const renderContent = () => {
        if (!test) {
            return <div>Cargando...</div>;
        }
        switch (currentPage) {
            case 1:
                return <Pagina1 first={test[0]} second={test[1]} actual={currentTest.title} />;
            case 2:
                return <Pagina2 first={test[2]} second={test[3]} third={test[4]} fourth={test[5]} actual={currentTest.title} />;
            case 3:
                return <Pagina3 actual={currentTest.title} />;
            case 4:
                return <Pagina4 actual={currentTest.title} first={test[11]} second={test[12]} onSaveAnswers={(saveAnswers) => saveAnswersRef.current = saveAnswers} />;
            case 5:
                return <Pagina5 />;
            default:
                return null;
        }
    };

    const showSubmitPopup = () => {
        setSubmitPopupVisible(true);
    };

    const closeHelpPopup = () => {
        setHelpPopupVisible(false);
        setPopupImage(null);
    };

    const closeSubmitPopup = () => {
        setSubmitPopupVisible(false);
    };

    const clearStorageAndRedirect = () => {
        const keysToRemove = [
            'currentTest',
            'selected0',
            'selected1',
            'selected2',
            'selected3',
            'selected4',
            'selected5',
            'selected6'
        ];
        keysToRemove.forEach(key => localStorage.removeItem(key));
        navigate('/choose-wine');
    };

    return (
        <div className="test-container">
            <div className="slider-container my-3">
                <button className="stats-close" onClick={clearStorageAndRedirect}><img src={close} alt="close" /></button>
                {renderContent()}
                <div className="btn-test-container">
                    <button className="btn btn-back" onClick={handlePrev} disabled={currentPage === 1}>Anterior</button>
                    {currentPage === 4 ? (
                        <button className="btn btn-next" onClick={showSubmitPopup}>Enviar</button>
                    ) : (
                        <button className="btn btn-next" onClick={handleNext}>Siguiente</button>
                    )}
                </div>
                <div className="btn-second-test-container">
                    <button className="btn btn-inst" onClick={handleShowInstructions}><span>Instrucciones</span></button>
                    <button className="btn btn-help" onClick={handleHelpClick}><span>Pistas</span></button>
                </div>
            </div>
            <TwoButtons />
            {isHelpPopupVisible && popupImage && (
                <Popup
                    imageUrl={popupImage}
                    onClose={closeHelpPopup}
                    isHelpPopup={true} 
                />
            )}
            {isSubmitPopupVisible && (
                <Popup
                    text="Estás a punto de enviar tus respuestas para este vino, una vez enviadas no podrás cambiarlas ¿Estás seguro?"
                    onClose={closeSubmitPopup}
                    onConfirm={handleSaveAnswers}
                    isHelpPopup={false} 
                />
            )}
        </div>
    );
};

const Popup = ({ text, onClose, onConfirm, imageUrl, isHelpPopup }) => {
    return (
        <div className="custom-overlay">
            <div className={`custom-popup ${isHelpPopup ? 'popup-noback' : ''}`}>
                <button className="stats-close" onClick={onClose}><img src={close} alt="close" /></button>
                {text && <p>{text}</p>}
                {imageUrl && (
                    <div className="image-container">
                        <img src={imageUrl} alt="Ayuda" />
                    </div>
                )}
                {onConfirm && <button onClick={onConfirm}>Enviar</button>}
            </div>
        </div>
    );
};

export default SliderComponent;